




















































import { Component, Vue } from 'vue-property-decorator';
import { IUserProfile } from '@/interfaces';
import { readAdminUsers, dispatchGetUsers, dispatchDeleteUser, dispatchDeleteUsers } from '@/store/admin/accessors';

@Component({
  filters: {
    parseServices(services: any) {
      return services.map((x) => x.service).join(', ');
    },
  },
})
export default class AdminUsers extends Vue {
  public selected: IUserProfile[] = [];
  public headers = [
    {
      text: this.$vuetify.lang.t('$vuetify.login'),
      sortable: true,
      value: 'login',
      align: 'left',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.isActive'),
      sortable: true,
      value: 'isActive',
      align: 'left',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.isSuperuser'),
      sortable: true,
      value: 'isSuperuser',
      align: 'left',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.services'),
      sortable: true,
      value: 'services',
      align: 'left',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.actions'),
      value: 'id',
    },
  ];

  get users() {
    return readAdminUsers(this.$store);
  }

  get currentUser() {
    return this.$store.state.main.userProfile;
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
  }

  public async deleteUser(id: number) {
    if (confirm(this.$vuetify.lang.t('$vuetify.deleteUsersConfirmation'))) {
      await dispatchDeleteUser(this.$store, id);
      await dispatchGetUsers(this.$store);
    }
  }

  public async deleteUsers() {
    if (confirm(this.$vuetify.lang.t('$vuetify.deleteUsersConfirmation'))) {
      const selected = this.selected.map((item) => item.id as number);
      await dispatchDeleteUsers(this.$store, selected);
      await dispatchGetUsers(this.$store);
    }
  }
}
